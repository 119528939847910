import React from 'react'

import KillerText from '../KillerText'
import "./Elevkåren.css";

function Elevkåren() {
    return (
        <div>
            <KillerText text="Anmälan"/>

            <div className="Elevkåren">
                <h1 className="OBS">OBS!</h1>
                <p>Kom ihåg att du <strong>måste vara medlem</strong> i Enskildas Elevkår för att få medverka i KILLER. (Det är gratis)</p>
                <br />
                <br />
                <p>(Även <strong>tvåor</strong> och <strong>treor</strong> behöver <strong>förnya</strong> sitt medlemskap, klicka på länken.)</p>
                <br />
                <h3>Bli medlem i Enskildakåren: <a target="_blank" rel="noreferrer" href="https://ebas.gymnasiet.sverigeselevkarer.se/signups/index/351">Klicka här!</a> </h3>
                <br />
                <p>Enskildas Elevkår är en förening som vi elever på skolan har möjlighet att vara medlemmar i. Enskildakårens mål är att skapa en så rolig och bra skolgång som möjligt för alla elever på skolan och fixar därför en rad olika aktiviteter under läsårets gång.</p>
                <p>Som medlem behöver du inte göra någonting mer än att få vara en del av alla härligheter på skolan, tack vare att du och dina vänner är medlemmar i Enskildas elevkår har vi i styrelsen möjligheten och pengarna till att t.ex. köpa in priser till våra olika tävlingar. </p>
                <p>Tack för att DU är medlem!</p>
                <br />
                <p>Med vänliga hälsningar</p>
                <p>Enskildkårens styrelse</p>
            </div> 
        </div>
    )
}

export default Elevkåren
