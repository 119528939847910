import React from 'react'
import KillerText from '../KillerText'
import KillerGuide from './KillerGuide'

export interface Guide{
    header:string,
    text:JSX.Element
}

function KillerGuiden() {
    return (
        <div>
            <KillerText text="KILLER guiden"/>
            <div style={{margin:"2rem"}}>
                {guides.map((i,j)=>{return(<KillerGuide key={j} header={i.header} text={i.text}/>)})}
            </div>
        </div>
    )
}

const guides:Guide[] = [
    {
        header:"",
        text:<>Spelet går ut på att döda ditt specifika offer och ej bli dödad av den som ska döda dig. När du dödat ditt offer får du offrets offer och kan fortsätta din räd mot finalen. </>
    },
    {
        header:"Ditt offer:",
        text:<>Du blir tilldelad ditt första offer via den mejlen du anger vid anmälningstillfället. Därefter får du offrets offer och så vidare. </>
    },
    {
        header:"",
        text:<>Exempel: Om A dödar B (och B skulle ha dödat C) så blir A's nya offer C.</>
    },
    {
        header:"Hur man utför ett mord:",
        text:<>Du dödar ditt offer genom att gå fram till offret och säga “Du är död” samtidigt som du vidrör offret på axeln. Vittnesmål inom 5 sekunder eller användande av Dagens Skydd ogiltiggör mordet. Offret måste höra dödförklaringen (förutsatt att offret inte använder medel för att nedsätta hörseln, exempelvis med hörlurar eller hörselskydd).</>
    },
    {
        header:"Hur man vittnar:",
        text:<>När man har sett ett mord äga rum har man ett val att vittna som åskådare. Detta gör man genom att säga "Jag vittnar". Vittnesmålet ogiltiggör mordet och offret är räddat. Vittnesmålet måste ske inom 5 sekunder efter att mordet har skett för att vara giltigt. Offret får ALDRIG be om vittnesmål efter att mordet begåtts. Ber man på något sätt om vittnesmål ogiltiggör man alla eventuella vittnesmål. Vittnesmålet måste höras av mördaren (förutsatt att mördaren inte använder medel för att nedsätta hörseln, exempelvis med hörlurar eller hörselskydd).</>
    },
    {
        header:"När du har begått ett mord:",
        text:<>När du begått ett lyckat mord, utan att någon vittnar, ska du anmäla ditt mord via mail: <a href="mailto:enskildakiller@gmail.com">enskildakiller@gmail.com</a> (läs regel 6)</>
    },
    {
        header:"När du har blivit dödad:",
        text:<>När du blivit dödad ska du anmäla det via mail: <a href="mailto:enskildakiller@gmail.com">enskildakiller@gmail.com</a> (läs regel 6)</>
    },
    {
        header:"Dagens Vapen:",
        text:<>Dagens Vapen förekommer vissa dagar under spelets gång. Med vapnet kan man döda sitt offer oavsett vittnesmål eller ej. Vapnet kan förekomma i en rad olika former. För att minimera risken att folk stannar hemma kan vapen komma att avslöjas samma dag som det gäller eller i form av så kallat flashvapen.</>
    },
    {
        header:"Dagens Skydd:",
        text:<>Dagens Skydd har samma koncept som vapnet men de två fyller olika funktioner. I stället för att kunna döda ditt offer oavsett vittnesmål så kan man med skyddet överleva mordförsök utan vittnesmål. Finns det både skydd och vapen samma dag är det skyddet som står över vapnet.  </>
    },
    {
        header:"Dagens Vittnesbevis:",
        text:<>Dagens Vittnesbevis förekommer vissa dagar och innebär att man behöver ha ett specifikt föremål för att kunna bevittna ett mord. Medans ett vittnesbevis är aktivt så innebär det att vanliga vittnesmål inte fungerar som ett sätt att stoppa ett mord.</>
    },
    {
        header:"",
        text:<>Gemensamt för både vapen och skydd är att de måste vara synliga vid användning och om det förekommer speciella instruktioner för hur de ska bäras är det viktigt att instruktionerna följs för att vapnet/skyddet ska vara giltigt.</>
    },
    {
        header:"",
        text:<>Vapen, Skydd och Vittnesbevis annonseras på <a href="https://www.instagram.com/enskildakaren/" target="_blank" rel="noreferrer">Enskilda Kårens Instagram</a>. För att minimera risken att folk stannar hemma kan skydd komma att avslöjas samma dag som det gäller eller i form av så kallat flashvapen.</>
    },
    {
        header:"",
        text:<>Löpande information under spelets gång hittar man här på hemsidan, däribland uppdateringar av regler och diverse statistik.</>
    },
    {
        header:"",
        text:<br></br>
    },
    {
        header:"Kreativitet och mål",
        text:<></>
    },
    {
        header:"",
        text:<>Vissa är med i KILLER för att ha kul, andra för att vinna. För att lyckas så bra som möjligt är det viktigt att tänka utanför boxen. Det är tillåtet och uppmuntras att använda sig av nya metoder för att hitta och döda sina offer, så länge de inte bryter mot reglerna. Lycka till!</>
    }
];

export default KillerGuiden
