import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { emptyUser, User } from './Interfaces/Account';
import KillerRoutes from './Routes/KillerRoutes';

require('dotenv').config()

let deferredPrompt:any; 

function App() {
  const [user, setUser] = useState<User>(emptyUser);
  const [installable, setInstallable] = useState(false);
    
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI notify the user they can install the PWA
        setInstallable(true);
        console.log("Test","13132131321");
      });
  
      window.addEventListener('appinstalled', () => {
        // Log install to analytics
        console.log('INSTALL: Success');
      });
    }, []);

    const handleInstallClick = (e:any) => {
      // Hide the app provided install promotion
      setInstallable(false);
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult:any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });
  };
  
  return (
    <Router>
      <KillerRoutes user={user} setUser={setUser} installable={installable} handleInstallClick={handleInstallClick}/>
    </Router>
  );
}

export default App;
