import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import {Bar,Pie} from 'react-chartjs-2';
import { Data } from './StatsKiller';

enum Direction {"x","y"}

interface Props{
    data:Data[],
    label:string,
    renderType:"bar"|"percent"|"doughnut"
}

function StatKiller({data,label,renderType}:Props) {
    const [dir,setDir] = useState<Direction>(Direction.x);
    
    
    const dataHighValue:number = Math.max(...data.map((i)=>i.count))
    const dataLength = data.length;

    if(renderType !== "doughnut"){
        return (
            <div style={{height:"80vh",textAlign:"center",marginBottom:"10vh",marginLeft:"1rem",marginRight:"1rem"}}>
                <Bar
                    data={{
                        labels:data.map((i)=>i.label),
                        datasets:[{
                            data:data.map((i)=>i.count),
                            backgroundColor:(renderType==='percent')?"rgba(41, 128, 185,0.6)":"rgba(231, 76, 60,0.8)",
                            borderColor:(renderType==='percent')?"rgba(41, 128, 185,1)":"rgba(231, 76, 60,1.0)",
                            borderWidth:1
                        }]
                    }}
                    options={
                        {
                            indexAxis:(dir===Direction.x)?"x":"y",
                            responsive:true,
                            maintainAspectRatio:false,
                            scales:{
                                yAxes:{
                                    ticks:{
                                        autoSkip:false,
                                        stepSize:1,
                                        sampleSize:30,
                                        callback:(value)=>{
                                            if(dir===Direction.y){
                                                if(typeof(value)==="string"){
                                                    return(value);
                                                }
                                                return(data[value].label);
                                            }
                                            if(renderType==="bar"){return value;}
                                            return(value + "%")
                                        }
                                    },
                                    min:0,
                                    max:(dir===Direction.x)?dataHighValue+1:dataLength,
                                    beginAtZero:true
                                },
                                xAxes:{
                                    ticks:{
                                        autoSkip:false,
                                        stepSize:1,
                                        sampleSize:30,
                                        maxRotation:(dir===Direction.x)?45:(renderType==="percent")?45:0,
                                        minRotation:(dir===Direction.x)?45:(renderType==="percent")?45:0,
                                        callback:(value)=>{
                                            if(dir===Direction.x){
                                                if(typeof(value)==="string"){
                                                    return(value);
                                                }
                                                return(data[value].label);
                                            }
                                            if(renderType==="bar"){return value;}
                                            return(value + "%")
                                        }
                                    },
                                    min:0,
                                    max:(dir===Direction.x)?dataLength:dataHighValue+1,
                                    beginAtZero:true
                                }
                            },
                            plugins:{
                                title:{
                                    display:true,
                                    text:label
                                },
                                legend:{
                                    display:false
                                }
                            }
                        }
                    }
                />
                <Button onClick={()=>setDir(prev=>(prev===Direction.x)?Direction.y:Direction.x)}>Ändra riktning</Button>
            </div>
        )
    }

    return(
        <div style={{height:"80vh",textAlign:"center",marginBottom:"10vh",marginLeft:"1rem",marginRight:"1rem"}}>
            <Pie 
                data={{
                    labels:data.map((i)=>{return(i.label + " " + i.count + "%")}),
                    datasets:[{
                        data:data.map((i)=>i.count),
                        backgroundColor:data.map((i)=>i.color),
                        borderColor:data.map((i)=>i.edgeColor),
                        borderWidth:3
                    }],
                }}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: label
                        }
                    }
                }}
            />
        </div>
    )
}

export default StatKiller
