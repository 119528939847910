import React from 'react'
import KillerText from '../KillerText';
import RegelKiller, { GetRegelArray } from './RegelKiller'

import "./ReglerKiller.css";

function ReglerKiller() {

    return (
        <div>
            <KillerText text="Regler"/>
            {GetRegelArray().map((i,j)=>{
                return(<RegelKiller key={j} index={j}/>);
            })}
        </div>
    )
}

export default ReglerKiller
