import React, { useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Elevkåren from '../Components/Elevkåren/Elevkåren';
import KillerGuiden from '../Components/KillerGuiden/KillerGuiden';
import MainKiller from '../Components/Main/MainKiller';
import NavbarKiller from '../Components/NavbarKiller';
import ReglerKiller from '../Components/Regler/ReglerKiller';
import StatsKiller from '../Components/Stats/StatsKiller';
import Microsoft from '../Components/Microsoft';
import { User } from '../Interfaces/Account';
import NotFound from '../Components/NotFound/NotFound';
import PWADownload from '../Components/PWA/PWADownload';

interface Props{
    user:User,
    setUser:React.Dispatch<React.SetStateAction<User>>,
    installable:boolean,
    handleInstallClick: (e: any) => void
}

function KillerRoutes({user,setUser,installable, handleInstallClick}:Props) {
    const [locationBeforeLogin,setLocBeforeLogin] = useState("/");
    let navigate = useNavigate();
    let location = useLocation();

    const loginRoutes:string[] = useMemo(()=>[
        "/",
        "/regler",
        "/hur",
        "/elevkaren",
        "/stats",
        "/login"
    ],[])

    useEffect(()=>{
        let haveFound = false;
        loginRoutes.forEach((val)=>{
            if(location.pathname === val){
                haveFound = true;
            }
        });

        if(haveFound === false){
            return;
        }

        if(!user.active && window.location.href.indexOf("/login") === -1){
            setLocBeforeLogin(location.pathname);
            navigate("/login");
        }else if(user.active && window.location.href.indexOf("/login") !== -1){
            navigate(locationBeforeLogin);
        }
    },[user, navigate, location, locationBeforeLogin,loginRoutes]);

    return (
        <div>
            <NavbarKiller/>
            <Routes>
                <Route path="/login" element={<Microsoft setUser={setUser}/>}/>
                <Route path="/regler" element={<ReglerKiller/>}/>
                <Route path="/stats" element={<StatsKiller/>}/>
                <Route path="/hur" element={<KillerGuiden/>}/>
                <Route path="/elevkaren" element={<Elevkåren/>}/>
                <Route path="/pwa" element={<PWADownload installable={installable} handleInstallClick={handleInstallClick} />}/>
                <Route path="/" element={<MainKiller installable={installable} handleInstallClick={handleInstallClick} />}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </div>
    )
}

export default KillerRoutes
