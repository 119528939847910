import React from 'react'

import "./KillerText.css";

interface Props{
    text:string
}

function KillerText({text}:Props) {
    return (
        <div className="RegelTop">
            <img className="RegelTopImg" src="https://ssl.gstatic.com/atari/images/label-maker-header-cropped.jpg" alt="" />
            <h2 className="RegelTopText">{text}</h2>
        </div>
    )
}

export default KillerText
