import React from 'react'
import { Alert } from 'react-bootstrap';
import KillerText from '../KillerText';
import StatKiller from './StatKiller';

export interface Data{
    label:string,
    count:number,
    color?:string,
    edgeColor?:string
}
function StatsKiller() {
    const data:Data[]=[
        {
            label:"Na21B",
            count:7
        },
        {
            label:"Na21A",
            count:5
        },
        {
            label:"Ek21B",
            count:10
        },
        {
            label:"Ek21A",
            count:15
        },
        {
            label:"Sa21B",
            count:2
        },
        {
            label:"Sa21A",
            count:4
        },{
            label:"Na21B",
            count:7
        },
        {
            label:"Na21A",
            count:5
        },
        {
            label:"Ek21B",
            count:10
        },
        {
            label:"Ek21A",
            count:15
        },
        {
            label:"Sa21B",
            count:2
        },
        {
            label:"Sa21A",
            count:4
        },{
            label:"Na21B",
            count:7
        },
        {
            label:"Na21A",
            count:5
        },
        {
            label:"Ek21B",
            count:10
        },
        {
            label:"Ek21A",
            count:15
        },
        {
            label:"Sa21B",
            count:2
        },
        {
            label:"Sa21A",
            count:4
        }
    ];
    const helaSkolanData:Data[]=[
        {
            count:42,
            label:"Levande",
            color:"rgba(39, 174, 96,0.7)",
            edgeColor:"rgba(39, 174, 96,1)"
        },{
            count:58,
            label:"Döda",
            color:"rgba(231, 76, 60,0.7)",
            edgeColor:"rgba(231, 76, 60,1)"
        }
    ]

    return(
        <div>
            <KillerText text="Statistik"/>
            <Alert style={{textAlign:"center"}} variant={"warning"}>
                Varning! Denna statistik är endast för att testa systemet. Riktig statistik kommer snart.
            </Alert>
            <StatKiller renderType="bar" label="Kills per klass" data={data}/>
            <StatKiller renderType="percent" label="Överlevande % per klass" data={data}/>
            <StatKiller renderType="doughnut" label="Hela Skolan" data={helaSkolanData}/>
        </div>
    )
}

export default StatsKiller
