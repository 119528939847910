import React from 'react'
import { useNavigate } from 'react-router'

function NotFound() {
    const navigate = useNavigate();

    return (
        <div>
            <h1>Hemsidan hittades inte</h1>
            <h3>Använd någon av länkarna nedan:</h3>
            <ul>
                <li onClick={()=>navigate("/")}>Hem</li>
                <li onClick={()=>navigate("/regler")}>Regler</li>
                <li onClick={()=>navigate("/stats")}>Statistik</li>
                <li onClick={()=>navigate("/hur")}>Hur killer fungerar</li>
                <li onClick={()=>navigate("/elevkaren")}>Elevkåren</li>
            </ul>
        </div>
    )
}

export default NotFound
