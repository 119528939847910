import React from 'react'

import { Guide } from './KillerGuiden'
import "./KillerGuiden.css";

function KillerGuide({header,text}:Guide) {
    return (
        <div className="Guide">
            <p><strong>{header}{header.length > 0 ? " ":""}</strong>{text}</p>
        </div>
    )
}

export default KillerGuide
