import React from 'react'
import PWADownload from '../PWA/PWADownload';

interface Props{
  installable:boolean,
  handleInstallClick: (e: any) => void
}

function MainKiller({installable, handleInstallClick}:Props) {
    return (
        <div>
          <PWADownload installable={installable} handleInstallClick={handleInstallClick} />
        </div>
    )
}

export default MainKiller
