import React from 'react'
import { Button } from 'react-bootstrap';

import "./PWADownload.css";


interface Props{
  installable:boolean,
  handleInstallClick: (e: any) => void
}

function PWADownload({installable, handleInstallClick}:Props) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log("This is running as standalone.");
      return(
        <div className="PWAcontainer">
          <h1>Hej, du verkar redan ha laddat ner appen.</h1>
          <p>Klicka uppe till höger på de 3 strecken och utforska den nya Killer hemsidan.</p>
          <p>Frågor om hemsidan kan skickas till <a href="mailto:hawaiilive@outlook.com">hawaiilive@outlook.com</a></p>
        </div>
      )
    }else{
      console.log("Nope no stand alone here");
    }

    return (
        <div className="PWAcontainer">
            <h1>Ladda ner Killer appen till din telefon!</h1>
            <p>Tryck på knappen nedan och följ instruktionerna för att appen ska laddas ner på din telefon.</p>
            <p>Om det inte funkar rekomenderar vi att du rensar din cache och försöker igen.</p>
            <div className="PWAbuttonContainer">
              {installable ? 
              <Button onClick={handleInstallClick}>
                Ladda ner KILLER appen
              </Button>
              :
              <div>
                <p>Om du har en iPhone behöver du följa några andra steg för att kunna ladda ner appen.</p>
                <ol>
                  <li>Öppna denna hemsida i Safari om du inte redan är där.</li>
                  <li>Klicka på knappen längst ner i mitten med en fyrkant och en pil som pekar uppåt.</li>
                  <li>Scrolla ner tills du ser knappen "Lägg till på hemskärmen" och klicka på den knappen.</li>
                  <li>Du kan nu ändra namn på appen och sedan klicka på "Lägg till" uppe i det högra hörnet.</li>
                </ol>
                <p>Klart. Appen finns nu på din hemskärm!</p>
              </div>
            }
            </div>
        </div>
    )
}

export default PWADownload
