import React, { useEffect, useState, useMemo } from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./NavbarKiller.css";
import { useLocation, useNavigate } from 'react-router';

import Logo from "../Images/Logo.jpg";

interface navLink {
    path:string,
    name:string
}

function NavbarKiller() {
    const [show, setShow] = useState(false);
    const [currentIndex, setIndex] = useState(0);

    const navs:navLink[] = useMemo(()=>[
        {
            name:"Hem",
            path:"/"
        },{
            name:"Statistik",
            path:"/stats"
        },{
            name:"Regler",
            path:"/regler"
        },{
            name:"Så här fungerar killer",
            path:"/hur"
        },{
            name:"Elevkåren",
            path:"/elevkaren"
        }
    ],[]);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(()=>{
        navs.map((item,index)=>{
            if(item.path === location.pathname){
                setIndex(index);
                return item;
            }
            return item;
        })
    },[location.pathname, navs])

    const clickedNav = (path: string, i:number)=>{
        setShow(false);
        if(i !== -1){
            setIndex(i);
        }
        navigate(path);
    }

    const clickedPWA = ()=>{
        setShow(false);
        window.location.href = "https://killer.hawaiidev.net/pwa";
    }

    return (
        <div >
            <Navbar collapseOnSelect={true} sticky="top" bg="light" expand={"lg"}>
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img
                            src={Logo}
                            height="38px"
                            className="d-inline-block align-top"
                            alt="Enskilda kåren logo"
                        />{' '}
                        <strong className="KillerLogo">Killer {new Date().getFullYear()}</strong></Navbar.Brand>
                    
                    <Navbar.Toggle onClick={()=>setShow(true)} aria-controls="offcanvasNavbar" />
                    <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                    show={show}
                    onHide={()=>setShow(false)}
                    style={{width:"60%"}}
                    >
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{padding:"0px"}}>
                        <Nav className="justify-content-end flex-grow-1">
                            {navs.map((item,i)=>{
                                return(
                                    <Nav.Link key={i} onClick={()=>clickedNav(item.path,i)} as={()=>{return(
                                        <div onClick={()=>clickedNav(item.path,i)} className={`nav-link ${i === currentIndex ? "navLinkOn" : "navLinkOff"}`}><h3>{item.name}</h3></div>
                                    )}}></Nav.Link>
                                );
                            })}
                        </Nav>
                        {(window.matchMedia('(display-mode: standalone)').matches === false) && <Nav.Link onClick={()=>clickedPWA()}>Ladda ner appen</Nav.Link>}
                    </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavbarKiller
